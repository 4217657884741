

































































import { FrontendApproveBalancer } from '@/includes/logic/ApproveBalancer/types'
import { InputSetups } from '@/mixins/input-setups'
import AddEntityCard from '../AddEntityCard.vue'
import { addExtension } from '@/includes/logic/ApproveBalancer/ApproveBalancerLogic'
import ApproveBalancerExtensions from './ApproveBalancerExtensions.vue'

import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

@Component({
  components: {
    EmptyData,
    AddEntityCard,
    ApproveBalancerExtensions,
    ConfigField
  },
  data() {
    return {
      StepUnit,
      addExtension
    }
  }
})
export default class ApproveBalancerSettings extends Mixins(UseFields, InputSetups) {
  @VModel() data!: FrontendApproveBalancer
  
}
