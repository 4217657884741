var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approve-balancer-extensions"},_vm._l((_vm.extensions),function(ext){return _c('a-card',{key:ext.guid,staticClass:"mb-2 last:mb-0",scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('a-icon',{staticClass:"text-xl text-danger hover:opacity-75 cursor-pointer",attrs:{"type":"delete"},on:{"click":function($event){return _vm.removeExtension(ext.guid)}}})]},proxy:true}],null,true)},[_c('div',{staticClass:"flex flex-col md:flex-row gap-5"},[_c('time-picker-input',{staticClass:"w-full mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': ext.value,
            'key': 'begin',
            'prefix': 'approve_balancer_extension_',
            'clearable': false,
            'validation': 'required',
            'hasAnchor': true,
          }
        }}}),_c('time-picker-input',{staticClass:"w-full mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': ext.value,
            'key': 'end',
            'prefix': 'approve_balancer_extension_',
            'clearable': false,
            'validation': 'required',
            'hasAnchor': true,
          }
        }}})],1),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': ext.value,
          'key': 'percent',
          'prefix': 'approve_balancer_extension_',
          'min': 0,
          'max': 100,
          'formatter': function (value) { return (value + " %"); },
          'parser': function (value) { return value.replace(' %', ''); },
          'hasAnchor': true,
        }
      }}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }