














import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class AddEntityCard extends Vue {
  @Prop() disabled!:boolean

  @Emit('click')
  onCardClick(): void {}

  handleCardClick():void {
    if (!this.disabled) {
      this.onCardClick()
    }
  }
}
