


































































import { FrontendApproveBalancer } from '../../includes/logic/ApproveBalancer/types'
import { InputSetups } from '@/mixins/input-setups'
import { removeExtension } from '@/includes/logic/ApproveBalancer/ApproveBalancerLogic'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      removeExtension
    }
  }
})
export default class ApproveBalancerExtensions extends Mixins(UseFields, InputSetups) {
  @VModel() extensions!: FrontendApproveBalancer['extensions']
}
