var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approve-balancer-settings"},[_c('a-card',{staticClass:"mb-5"},[_c('config-field',{attrs:{"slot":"title","title":_vm.$t('approve_balancer_main_settings'),"help-message":_vm.$t('approve_balancer_main_settings_help_message')},slot:"title"}),_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.data,
          'key': 'limit',
          'prefix': 'approve_balancer_',
          'min': 0,
          'hasAnchor': true,
        }
      }}}),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'inline': true,
          'model': _vm.data,
          'key': 'period',
          'prefix': 'approve_balancer_',
          'hasAnchor': true,
        }
      }}})],1),(!_vm.data.extensions.length)?_c('empty-data',{staticClass:"mb-2"}):[_c('h4',{staticClass:"flex text-xl"},[_vm._v(" "+_vm._s(_vm.$t('approve_balancer_extra_extensions_settings'))+" "),_c('sup',[_c('help-message',{attrs:{"help-message":_vm.$t('approve_balancer_extra_extensions_settings_help_message')}})],1)]),_c('approve-balancer-extensions',{model:{value:(_vm.data.extensions),callback:function ($$v) {_vm.$set(_vm.data, "extensions", $$v)},expression:"data.extensions"}})],_c('div',{staticClass:"mt-2",staticStyle:{"min-height":"50px"}},[_c('add-entity-card',{on:{"click":_vm.addExtension}},[_vm._v(" "+_vm._s(_vm.$t('approve_balancer_add_extension'))+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }