
























import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import ApproveBalancerSettings from '@/components/ApproveBalancer/ApproveBalancerSettings.vue'
import {
  balancerState,
  init,
  save,
  toggleBalancerActive,
  warnIfActiveChange
} from '@/includes/logic/ApproveBalancer/ApproveBalancerLogic'

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    ApproveBalancerSettings,
    PageTitle,
    SaveChannelConfig,
    CenteredColumnLayout
  },
  data() {
    return {
      balancerState,
    }
  }
})
export default class ApproveBalancerPage extends Vue {

  get toggleApproveBalancerButtonTitle() {
    if (balancerState.frApproveBalancer) {
      if (balancerState.frApproveBalancer.active) {
        return this.$t('disable_approve_balancer_button_title').toString()
      } else {
        return this.$t('activate_approve_balancer_button_title').toString()
      }
    }
  }
  get toggleApproveBalancerButtonType() {
    if (balancerState.frApproveBalancer) {
      if (balancerState.frApproveBalancer.active) {
        return 'danger'
      } else {
        return 'primary'
      }
    }
  }

  saveSettings() {
    save(this.$route)
  }

  toggleApproveBalancer() {
    toggleBalancerActive()
    warnIfActiveChange()
  }

  mounted() {
    this.$baseTemplate.saveButton.show(() => this.saveSettings())

    init()
  }

  destroyed() {
    this.$baseTemplate.saveButton.resetSaveButtonCallback()
    this.$baseTemplate.saveButton.hide()
  }
}
